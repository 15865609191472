<template>
  <div>
    <b-overlay
      :show="load"
      variant="transparent"
    >
      <b-card>
        <b-row
          align-h="center"
          class="mb-2"
        >
          <b-col
            md="8"
          >
            <b-form-input
              v-model="filter"
              placeholder="Pesquisar"
              type="search"
              size="sm"
            />
          </b-col>
        </b-row>
        <b-table
          hover
          striped
          responsive
          sticky-header="70vh"
          :fields="fields"
          :items="items"
          :filter="filter"
        >
          <template #cell(data)="data">
            {{ formatTimezone(data.item.data) }}
          </template>
          <template #cell(opcoes)="data">
            <b-row>
              <b-col
                cols="auto"
              >
                <feather-icon
                  icon="EyeIcon"
                  class="color-icon"
                  @click="demonstrativoAcordo(data.item)"
                />
              </b-col>
              <b-col
                cols="auto"
              >
                <feather-icon
                  icon="DollarSignIcon"
                  class="color-icon"
                  @click="pesquisaAvancada(data.item)"
                />
              </b-col>
            </b-row>
          </template>
        </b-table>
      </b-card>
    </b-overlay>
    <b-modal
      ref="modalPesquisa"
      hide-footer
    >
      <b-row class="mt-2">
        <b-col>
          <span class="text-danger">{{ avancadaError }}</span>
          <b-table
            sticky-header
            bordered
            small
            hover
            :items="clientes"
            :fields="[]"
            @row-clicked="setCliente"
          >
            <template #cell(cpf)="data">
              {{ cpfLabel(data.item.cpf) }}
            </template>
          </b-table>
        </b-col>
      </b-row>
    </b-modal>
    <b-modal
      ref="modalDemonstrativo"
      hide-footer
      size="xl"
    >
      <b-row>
        <b-col>
          <iFrame
            id="myFrame"
            :src="urlDemonstrativo"
            width="100%"
            height="700px"
          />
        </b-col>
      </b-row>
    </b-modal>
  </div>
</template>
<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BTable,
  BModal,
  BOverlay,
} from 'bootstrap-vue'
// eslint-disable-next-line import/no-cycle
import axios from '@/../axios-auth'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BTable,
    BModal,
    BOverlay,
  },
  data() {
    return {
      load: true,
      filter: '',
      fields: [
        {
          key: 'numero_acordo', label: 'acordo',
        },
        {
          key: 'cpf_cnpj_cliente', label: 'cpf',
        },
        {
          key: 'nome_executivo', label: 'executivo',
        },
        {
          key: 'credor',
        },
        'data',
        'opcoes',
      ],
      clientes: [],
      items: [],
      urlDemonstrativo: '',
      avancadaError: '',
    }
  },
  created() {
    this.getAcordos()
  },
  methods: {
    setCliente(item) {
      const url = this.$router.resolve({ name: 'negociacao-cliente', params: { id: item.id } })
      window.open(url.href, '_blank')
    },
    async pesquisaAvancada(item) {
      this.clientes = []
      const body = {
        campo: 'cpf',
        valor: item.cpf_cnpj_cliente,
      }

      await axios.post('api/v1/acordo/pesquisa/avancada', body, {
        headers: {
          Authorization: `Bearer ${localStorage.token}`,
          accept: 'application/json',
        },
      }).then(res => {
        const { dados } = res.data
        if (dados.length > 0) {
          this.avancadaError = ''
          res.data.dados.map(dt => {
            this.clientes.push({
              id: dt.id,
              nome: dt.nome,
              cpf: dt.cpf_cnpj,
            })
            this.$refs.modalPesquisa.show()
          })
        } else {
          this.avancadaError = 'Nenhum Cliente encontrado'
        }
      })
    },

    demonstrativoAcordo(item) {
      this.urlDemonstrativo = `https://api.grupovoz.com.br/demonstrativo/${item.numero_acordo}`
      this.$refs.modalDemonstrativo.show()
    },

    async getAcordos() {
      this.load = true
      await axios.get(`https://grupovoz.net.br/acordos/${localStorage.cpf}`).then(res => {
        this.items = res.data
      })
      this.load = false
    },
  },
}
</script>
